.login{
	font-family: FrutigerLT;
}

.login__container{
}

.login__column1,.login__column2{
	display: inline-block;
	vertical-align: middle;
}

.login__column1{
	width: 27%;
	margin-left: 175px;
}

.login__content{
	color: #003B71;
	font-weight: bold;
	font-size: 1.25rem;
	margin-bottom: 20px;
}

.login__action{
	color: #003B71;
	background: #FFD100;
	padding: 15px 10px;
	border-radius: 3px;
	border: unset;
	box-shadow: unset;
	cursor: pointer;
}

.login__column2{
	width: 50%;
}

.login__placeholder{
	width: 100%;
}

@media (max-width: 900px) {
	.login__column1,.login__column2{
		display: block;
		width: 90%;
		margin: 50px auto 20px auto;
		text-align: center;
	}
}